export default {
  init() {
      // Check for click events on the navbar burger icon
  $('.navbar-burger').click(function() {

    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    $('.navbar-burger').toggleClass('is-active');
    $('#mobile-navbar-menu .navbar-menu').toggleClass('is-active');

});
  

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
